import React from "react"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
import {
  Row,
  Col,
  Button,
  Accordion,
  Card,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const EventsPageTemplate = ({ title, section1, title2, agenda }) => {
  const colorArray = ["#d91572", "#005d41ff", "#e82a27ff", "#666129ff"]
  return (
    <Layout>
      <SEO title="Events" />
      <Row className="mx-0" style={{ marginTop: "-73px" }}>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center events-landing-image">
            <div className="overlay"></div>
            <div className="sectionTitleWrap">
              <h1 className="sectionTitle">{title}</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex flex-column align-items-center events-section2">
        <Col xs={10} lg={8}>
          <p>
            <ReactMarkdown source={section1} linkTarget="_blank" />
          </p>
        </Col>
      </Row>

      <Row className="events-section-agenda">
        <div className="overlay-top"></div>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center bg-image">
            <div className="overlay"></div>
            <div className="sectionTitleWrap">
              <h1 className="sectionTitle">{title2}</h1>
            </div>
          </div>
        </Col>
        <div className="overlay-bottom"></div>
      </Row>

      <Row className="d-flex flex-column justify-content-center align-items-center events-section-list">
        {agenda.map((event, i) => (
          <Col>
            <Accordion className="accordion">
              <Card style={{ backgroundColor: colorArray[i % 4] }}>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <Row className="d-flex flex-row align-items-center">
                    <Col xs={12} md={5} lg={{ span: 4, offset: 1 }}>
                      <div className="number">
                        {event.dateNum}
                        <div className="month">{event.dateMonth}</div>
                      </div>
                    </Col>
                    <Col xs={12} md={7} lg={6}>
                      <h2>{event.title}</h2>
                      <p style={{ color: "#ede7e1ff" }}>
                        {event.time}
                        <br />
                        {event.typeCost}
                      </p>
                      <p>
                        <a target="_blank" href={event.calLink}>
                          GOOGLE CALENDAR
                        </a>
                      </p>
                      <p style={{ color: "#ede7e1ff" }}>{event.content}</p>
                    </Col>
                  </Row>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <a href={event.register}>
                      <Button
                        style={{
                          backgroundColor: colorArray[i % 4],
                          borderColor: colorArray[i % 4],
                        }}
                      >
                        REGISTER
                      </Button>
                    </a>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        ))}
      </Row>

      <Row className="events-section-news d-flex flex-row justify-content-center align-items-center">
        <div className="overlay"></div>
        <Col xs={11} md={8} xl={6}>
          <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            <div className="sectionTitleWrapper">
              <h1 className="sectionTitle">Newsletter</h1>
              <p>
                Stay up-to-date about Lightschools’ events & receive inspiration
                with our newsletter, which appears 13 times a year.
              </p>
            </div>
          </div>
          <Form
            className="mt-5"
            action="https://t91884b07.emailsys1a.net/122/3739/5adaf24e63/subscribe/form.html"
            method="post"
            class="validate"
            target="_blank"
            novalidate
          >
            <Row>
              <Col xs={6}>
                <div
                  style={{
                    position: "absolute",
                    zIndex: "-100",
                    left: "-6000px",
                  }}
                  aria-hidden="true"
                >
                  <label for="rm_email">email: </label>
                  <input
                    type="text"
                    class="form_field"
                    name="rm_email"
                    id="rm_email"
                    value=""
                    tabindex="-1"
                  />
                  <label for="rm_comment">Comment: </label>
                  <textarea
                    name="rm_comment"
                    tabindex="-1"
                    id="rm_comment"
                  ></textarea>
                </div>
                <input
                  type="hidden"
                  name="url_ok"
                  value="https://lightschools.org/success"
                />
                <Form.Control
                  size="lg"
                  placeholder="First name"
                  name="first_name"
                />
              </Col>
              <Col xs={6}>
                <Form.Control
                  size="lg"
                  placeholder="Last name"
                  name="last_name"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <InputGroup className="mt-3">
                  <FormControl
                    size="lg"
                    placeholder="enter your email address"
                    aria-label="enter your email address"
                    aria-describedby="email address"
                    name="email"
                  />
                  <InputGroup.Append>
                    <Button type="submit">SIGN UP</Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={8} className="align-self-center"></Col>
      </Row>
    </Layout>
  )
}

const EventsPage = props => {
  const { frontmatter } = props.data.EventsPageTemplate
  console.log(frontmatter)
  return (
    <EventsPageTemplate
      title={frontmatter.title}
      section1={frontmatter.section1}
      title2={frontmatter.title2}
      agenda={frontmatter.agenda}
    />
  )
}

export default EventsPage

export const pageQuery = graphql`
  query($id: String!) {
    EventsPageTemplate: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        section1
        title2
        agenda {
          title
          time
          typeCost
          calLink
          register
          dateNum
          dateMonth
          content
        }
      }
    }
  }
`
